.big-box{
    width: 75%;
    margin: 0 12.5%;
}
.project-box{
    margin: 50px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 500px;
    flex-wrap: wrap;
    
    }
    .project-image{
        min-width: 200px;
        min-height: 350px;

        /* background-color: blueviolet; */
        position: relative;
        flex-basis: 60%;  
        flex-grow: 1;  
    }
    .project-image img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
    }
    .project-details{
        min-width: 360px;
        background-color: #0E223B;
        padding: 35px ;
        flex-basis: 40%;
        flex-grow: 1;  

    }

    .project-details h3{
        color: white;
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        text-decoration: underline;
        font-weight: 100;
        text-transform: uppercase;
        margin: 0;
    }

    .project-details p{
        color: white;
        width: 100%;
        font-size: 1rem;
        font-weight: 600;
        text-transform: capitalize;
        text-align: left;
        margin: 10px 0 30px;
    }

   .big-box p{
        text-align: left;
        width: 100%;
        max-width: 100%;
        /* margin-top: -30px; */
    }

    @media(max-width:768px){

      .project-details{
        min-width: 300px;

    }
    
  .project-details h3 {
      font-size: .8rem;
  }

  .project-details p {
    font-size: .8rem;
}
    }


/* ///////////// */
.carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .carousel-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    transition: opacity 1s ease-in-out;
  }
  
  .carousel-slide.active {
    display: block;
  }
  
  .carousel-indicators {
    text-align: center;
    margin-top: 10px;
  }
  
  .carousel-indicators button {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    margin: 0 5px;
    padding: 0;
    background-color: white;
    transition: background-color 0.3s ease-in-out;
  }
  
  .carousel-indicators button.active {
    background-color: #555;
  }
  
  .carousel-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .carousel-control {
    cursor: pointer;
    padding: 5px;
    background-color:transparent;
    color: #fff;
    border: none;
  }
  



/* ///////////// */



