.navbar{
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
  height: 90px;
}

.navbar-container{
  width: 75%;
  margin: 15px 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-scrolled {
  background-color: white;  
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.brand{
  height: 90px;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease-in-out;
}

.brand-disabled{
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.brand-enabled{
  opacity: 100;

}

.nav-menu{
  width: 30%;
  max-width: 350px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: white;
  margin-bottom: -1px;
}

.navLink{
  font-family: 'Anton', sans-serif;
    font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 0;
}

.navLink:hover{
  transition: 0.3s ease-in-out;
  color: #D1B479;
  /* border-bottom: 1px solid;
  border-color: darkgrey; */
}

.navLink-basic{
  color: white;
}

.navLink-scrolled{
  color: #0E223B;
}

/* .navLink-scrolled:hover{
  border-bottom: 0;

} */

.hamburger{
  display: none;
  cursor: pointer;
}

.bar{
  display: block;
  background-color: white;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


.bar-scrolled{
  background-color: black;
}


@media(max-width:768px){
  .hamburger{
    display: block;
  }
  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .nav-menu{
    position: fixed;
    top: -100%;
    left: 0;
    flex-direction: column;
    background-color: white;
    width: 100%;
    max-width: 100%;
    height:fit-content;
    text-align: center;
    transition: 0.3s;
    z-index: -1;
  }
  .nav-menu.active{
    top: 90px;
    padding: 15px 0;
  }

  .navLink{
    color: black;
  }

  .floortec{
    display: none;
  }
}

.floortec{
  margin-left: -130px;
  width: 130px;
  height: auto;
  top: 16px;
}