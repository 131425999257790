.info-section{
    width: 100%;
    min-height: 48vh;
    padding: 50px 12.5% 100px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;

}

.info-col{
    display: flex;
    flex-direction: column;     
    flex: 1;
    gap: 40px;
}

.info-section h3{
    font-weight: 600;
}

.info-section p{
    text-align: left;
    width: 100%;
    max-width: 100%;
}

.info-section img{
    width: 50px;
}

.info-card{
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    min-width: 375px;
    width: 30vw;
    height: 20vw;
    padding: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.info-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}




.button-info{
    width: fit-content;
    margin-left: 70px;
}



.info-video{
    width: 40vw;
    height: 22.5vw;
}

@media(max-width:1186px){

    .info-col{

        flex-direction: row;     

    }
    .info-video{
        width: 75vw;
        height: 42.2vw;
    }


}

@media(max-width:1054px){



    .info-col{
        flex-direction: column;     
        align-items: center;
        width: 100%;
}


}

@media(max-width:500px){



    .info-card{

        width: 100%;
        min-width: 280px;
}


}