
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

  /* .footer-flex{
  background-color: brown;
  flex: 1;
}
footer{
  margin-top: auto;
} */

/* html, body {
    height: 100%;
    margin: 0;
  } */
  

  h1{
text-transform: uppercase;
font-family: 'Anton', sans-serif;
width: 100%;
    max-width: 650px;

}

p{
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
    max-width: 650px;
}

.button{
  /* margin: 75px 0 50px; */
  width: fit-content;
  height: fit-content;
  padding: 10px 30px;
  background-color: white;
  /* outline: 4px solid; */
  font-family: 'Anton', sans-serif;  font-size: 1.5rem;
  text-transform: uppercase;
  border: 4px solid;
  border-color: black;
  color: black;
  transition: 0.3s ease-in-out;
}

.button:hover{
  /* outline-color: #D1B479; */
  border-color: #D1B479;
  color: #D1B479;
}

.projects-section{
  /* padding: 30px 0; */
  margin: 50px 12.5%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}



  .project-categories{
     /* display: flex; */
    display: none;
    width: 100%;
    margin: 0 0 30px;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .btn-cat {
    width: fit-content;
    height: fit-content;
    padding: 10px 30px;
    background-color: white;
    /* outline: 2px solid; */
    font-family: 'Anton', sans-serif;
        font-size: 1.2rem;
    text-transform: uppercase;
    border: 2px solid;
  }
  
  .btn-cat-active {
    border-color: #D1B479;
    color: #D1B479;
  }
  
  .btn-cat:focus-visible {
    outline: 2px solid #D1B479;
  }
  
  
  .project-cards{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 35px;
      margin: 0 0 45px;
  }

  .project-cards::after {
    flex-basis: 350px; 
    flex-grow: 1;
    content: ""; 
}

.hover-image{
  position: absolute;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project-card-effect{
  overflow: hidden;
}

.project-card-effect:hover .hover-image{
  transform: scale(1.2);
}


.services-section{
  margin: 50px 12.5% 100px;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.service-cards{
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  gap: 25px;
  margin: 0 0 45px;
}

.service .service-cards::after {
  flex-basis: 350px; 
  flex-grow: 1;
  content: ""; 
}

.news-page{
display: flex;
justify-content: center;
}

.news-content{
  width: 75%;
}

.news-content h2{
  width: 100%;
  font-size: 2rem;
  text-align: left;
}

.news-content img{
  width: 100%;
  height: 600px;
  object-fit: cover;
  margin: 20px 0;
}

.news-content p{
  width: 100%;
  max-width: 100%;
  text-align: left;
}


@media(max-width:768px){
  h2{
    /* font-size: 2.5rem; */
    font-size: 2rem;
    /* margin: 30px 0 0; */
  }
  
  h4{
    /* font-size: 1.5 rem; */
    font-size: .8rem;
  }
  
  p{
    /* font-size: 1rem; */
    font-size: .8rem;
  }

  .button{
    padding: 8px 20px;
    font-size: 1rem;
    border: 3px solid;
  }
  
  
  .service-card p{
    /* font-size: 1.3rem; */
    font-size: 1.2rem;
  }
  
  .button{
    /* font-size: 1.5rem; */
    font-size: 1.2rem
  }

 

  .projects-section{
    /* margin: 50px 12.5%; */
    margin: 10px 12.5% 50px;
  }


  .projects-section h2{
    font-size: 1.4rem;
  }

  .services-section-home{
    /* padding: 30px 0; */
    padding: 0 0;
    /* margin: 50px 12.5% 100px; */
    margin: 0 12.5% 50px;
  }

  .certif{
    /* padding: 100px 0; */
    padding: 50px 0;

    }
}


/* Animations */
.anim-lineUp {
  opacity: 0;
  transform: translateY(80%);
}

.animate {
  animation: anim-lineUp 2s ease-in-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
/* Animations */