
.contact-cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    margin: 45px 0;
  }
  .contact-cards::after {
    flex-basis: 350px; 
    flex-grow: 1;
    content: ""; 
  }
  .contact-card{
    background-color: white;
    width: 350px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .contact .text-left{
    background-color: #f8f8f8;
  }


.contact-card h3{
  font-size: 1.1rem;
  margin: 25px 25px 10px;

}
.contact-card p{
  font-size: .9rem;
  color: black;
  margin: 0;
}
.contact-card svg{
  fill: black;
  width: 20px;
  height: 20px;
  align-self: center;
}
.contact-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.item{
  display: flex;
  gap: 10px;
  margin: 5px 25px;
}

.contact h2{
  font-size: 2rem;
  text-align: left;
  margin-bottom: 15px;
}

.contact .button{
  margin-top: 30px;
  border-radius: 0;
}

.submit-area{
  width: 100%;
  gap: 25px;
  display: flex;
  align-items: flex-end;
}
  /* ///// inputs /////*/

  
  input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
  }
  
  .styled-input {
    float: left;
    width: 100%;
    margin: 1rem 0 0;
    position: relative;
    border-color: black;
    /* border: .2px solid; */
  }
  

  
  .styled-input label {
    color: #999;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
  }
  
  .styled-input.wide { 
    width: 100%;
    max-width: 100%;

  }
  
  input,
  textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color: white;
    color: Black;
    /* border-radius: 4px; */
  }
  
  input:focus,
  textarea:focus { outline: 0; }
  
  input:focus ~ span,
  textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
  }
  
  textarea {
    width: 100%;
    min-height: 15em;
  }
  
  
  input[type=checkbox] + label {
  color: #ccc;
  font-style: italic;
  } 
  
  input[type=checkbox]:checked + label {
  color: #f00;
  font-style: normal;
  }


 

  @media(max-width:768px){
    .contact .image{
        flex: 0;
    }

    .contact h2{
      font-size: 1.5rem;
     
    }
}

 