.header{
    position: relative;
    width: 100%;
    margin-top: -100px;
    display: flex;
    justify-content: center;
  }
  .header img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .header .title{
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

 }

 .header .content{
  width: 100%;


}

 .header h1{
    margin: 250px 0 15px;
    font-size: 2rem;
    color: white;
  }

  .header p{
    margin: 0 0 25px;
    text-align: left;
    color: white;
    width: 100%;

  }