.text-left{
    display: flex;
    align-items: stretch;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    min-height: 500px;
}
.text-right{
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    outline: .5px solid lightgray;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    min-height: 500px;
}

.image{
    flex: 1;
    position: relative;
}

.image img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
}


.text{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 75px 12.5%;
}
.text p{
    text-align: left;
    width: 100%;
    margin: 15px 0;
}



@media(max-width:900px){
    .text{
        flex: 3;
    }
}








